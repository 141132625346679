/*
|--------------------------------------------------------------------------
| Modules
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Aliases
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Partials
|--------------------------------------------------------------------------
*/
.printModal {
  font-family: sans-serif;
  display: flex;
  text-align: center;
  font-weight: 300;
  font-size: 30px;
  left: 0;
  top: 0;
  position: absolute;
  color: #045fb4;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); }

/*
|--------------------------------------------------------------------------
| Close Button
|--------------------------------------------------------------------------
*/
.printClose {
  position: absolute;
  right: 10px;
  top: 10px; }

.printClose:before {
  content: "\00D7";
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 100;
  line-height: 1px;
  padding-top: 0.5em;
  display: block;
  font-size: 2em;
  text-indent: 1px;
  overflow: hidden;
  height: 1.25em;
  width: 1.25em;
  text-align: center;
  cursor: pointer; }

.printSpinner {
  margin-top: 3px;
  margin-left: -40px;
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #045fb4;
  border-radius: 50%;
  animation: spin 0.75s infinite linear; }

.printSpinner::before, .printSpinner::after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit; }

.printSpinner, .printSpinner::before, .printSpinner::after {
  display: inline-block;
  border-color: transparent;
  border-top-color: #045fb4;
  animation-duration: 1.2s; }

.printSpinner::before {
  transform: rotate(120deg); }

.printSpinner::after {
  transform: rotate(240deg); }

/* Keyframes for the animation */
@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }


